import styled from '@emotion/styled'
import tw from 'twin.macro'

export const TwoColsGrid = styled.div`
  ${tw`
    relative
    lg:flex
    lg:flex-wrap
    lg:w-full
  `}
`

export const Column = styled.div`
  ${tw`
    lg:w-1/2
  `}
  &.column-1 {
    ${tw`
      pr-4
    `}
  }
`
