import styled from '@emotion/styled'
import tw from 'twin.macro'

export const ContentTitle = styled.div`
  ${tw`
    text-base
    mx-auto
    lg:max-w-none
  `}
  p {
    ${tw`
      text-base
      leading-6
      text-green-500
      font-semibold
      tracking-wide
      uppercase
    `}
  }
  p.users-management {
    ${tw`
      text-purple-700
    `}
  }
  h2 {
    ${tw`
      my-6
      text-3xl
      leading-8
      font-extrabold
      tracking-tight
      text-purple-900
      sm:text-4xl
      sm:leading-10
    `}
  }
  h2.on-dark-background {
    ${tw`
      text-white
    `}
  }
`

export const ContentDescription = styled.div`
  ${tw`
    relative
    z-10
    text-base
    mx-auto
    mb-6
    lg:max-w-full
  `}
  p {
    ${tw`
      text-lg
      text-purple-300
      leading-7
    `}
  }
  p.on-dark-background {
    ${tw`
      text-gray-600
    `}
  }
`

export const ContentCallToAction = styled.div`
  ${tw`
    text-base
    font-bold
    mb-8
  `}
  .authentication {
    ${tw`
      text-teal-500
  `}
  }
  .users-management {
    ${tw`
      text-purple-500
  `}
  }
  .pricing {
    ${tw`
      text-pink-500
  `}
  }
`
