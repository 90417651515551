import React from 'react'
import CallToAction from '../../../elements/call_to_action'
import { ContentCallToAction, ContentDescription, ContentTitle } from './style'

type ContentTitleSectionProps = {
  caption?: string | null
  title: string
  description: string
  className?: string
  call_to_action?: string
  call_to_action_path?: string
  slug?: string
  isCallToActionBtn?: boolean
}

const ContentTitleSection = ({
  caption,
  title,
  description,
  className,
  call_to_action,
  call_to_action_path,
  isCallToActionBtn,
  slug,
}: ContentTitleSectionProps) => {
  return (
    <>
      <ContentTitle>
        <p className={slug}>{caption}</p>
        <h2 className={className}>{title}</h2>
      </ContentTitle>
      <ContentDescription>
        <p className={className}>{description}</p>
      </ContentDescription>
      {isCallToActionBtn ? (
        <CallToAction>
          <a href={call_to_action_path} className="color pricing">
            Contact Sales
          </a>
        </CallToAction>
      ) : (
        <ContentCallToAction>
          <p className={slug}>{call_to_action}</p>
        </ContentCallToAction>
      )}
    </>
  )
}

export default ContentTitleSection
