import React from 'react'
import CallToActionSection from '../../components/call_to_action_section'
import CenterHero from '../../components/hero/center_hero'
import Layout from '../../components/layout'
import Faqs from '../../components/pricing/faqs'
import OemPricing from '../../components/pricing/oem_pricing'
import Plan from '../../components/pricing/plan'

const PricingPage = ({ pageContext: { data, localePath } }: any) => {
  const cardItems = data.body.filter((x: any) => x.__typename === 'PrismicLandingBodyCard')
  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <CenterHero
        title={data.header_title.raw}
        description={data.header_description.raw}
        textAlignment="center"
        call_to_action_color={data.slug}
      />
      <Plan slug={data.slug} cardItems={cardItems} />
      <OemPricing oemPricingData={data.body[3]} pageID={data.slug} />
      <Faqs faqsData={data.body[5]} pageID={data.slug} />
      <CallToActionSection callToActionSectionData={data.body[6]} />
    </Layout>
  )
}

export default PricingPage
