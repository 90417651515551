import React from 'react'
import { FaqTextAnswer, FaqTextQAndA, FaqTextQuestion, FaqTextWrapper } from './style'

const FaqText = ({ items }: any) => {
  return (
    <FaqTextWrapper>
      <dl>
        {items.map((item: { question: string; answer: string }, index: string | number) => {
          return (
            <FaqTextQAndA key={index}>
              <FaqTextQuestion>{item.question}</FaqTextQuestion>
              <FaqTextAnswer>{item.answer}</FaqTextAnswer>
            </FaqTextQAndA>
          )
        })}
      </dl>
    </FaqTextWrapper>
  )
}

export default FaqText
