import React from 'react'
import { ContentCallToAction, ContentDescription, ContentTitle } from './style'

type ContentTitleSectionProps = {
  caption?: string | null
  title?: string
  description?: string
  className?: string
  call_to_action?: string
  slug?: string
}

const ContentTitleSection = ({
  caption,
  title,
  description,
  className,
  call_to_action,
  slug,
}: ContentTitleSectionProps) => {
  return (
    <>
      <ContentTitle>
        <p className={slug}>{caption}</p>
        <h2 className={className}>{title}</h2>
      </ContentTitle>
      <ContentDescription>
        <p className={className}>{description}</p>
      </ContentDescription>
      {call_to_action && (
        <ContentCallToAction>
          <p className={slug}>{call_to_action}</p>
        </ContentCallToAction>
      )}
    </>
  )
}

export default ContentTitleSection
