import React from 'react'
import { GridItem, GridStyle } from './style'

const TwoXTwoGrid = ({ items, slug }: any) => {
  if (!items) return null
  return (
    <GridStyle>
      {items.map((item: { title: string; description: string }, index: string | number) => {
        return (
          <GridItem key={index}>
            <div>
              <h3 className={slug}>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </GridItem>
        )
      })}
    </GridStyle>
  )
}

export default TwoXTwoGrid
