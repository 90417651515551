import React from 'react'
import { Column, TwoColsGrid } from './style'

const TwoCols = ({ rightColumn, leftColumn }: any) => {
  return (
    <TwoColsGrid>
      <Column className="column-1">{leftColumn}</Column>
      <Column className="column-2">{rightColumn}</Column>
    </TwoColsGrid>
  )
}

export default TwoCols
