import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CardWrapper = styled.div`
  ${tw`
    mt-10
    shadow-xl
    rounded-lg
    max-w-lg
    mx-auto
    lg:mt-0
    lg:max-w-none
    lg:mx-0
    lg:col-start-3
    lg:col-end-6
    lg:row-start-1
    lg:row-end-4
  `}
  div {
    ${tw`
      relative
      z-10
    `}
  }
`

export const CardBorder = styled.div`
  ${tw`
    pointer-events-none
    absolute
    inset-0
    rounded-lg
    border-2
    border-indigo-600
  `}
`

export const CardBadge = styled.div`
  ${tw`
    absolute
    inset-x-0
    top-0
    transform
    translate-y-px
    bg-white
    rounded-lg
  `}
  div {
    ${tw`
      flex
      justify-center
      transform
      -translate-y-1/2
    `}
  }

  span {
    ${tw`
      inline-flex
      rounded-full
      px-4
      py-1
      text-sm
      leading-5
      font-semibold
      tracking-wider
      uppercase
      text-white
    `}
    &.cryptr-passwordless {
      ${tw`
        bg-purple-900
      `}
    }
    &.enterprise-connections {
      ${tw`
        bg-teal-500
      `}
    }
    &.no-category {
      height: 28px;
    }
  }
`

export const CardPriceWrapper = styled.div`
  ${tw`
    bg-white
    rounded-t-lg
    px-6
    py-3
  `}
`

export const CardPrice = styled.div`
  ${tw`
    mt-4
    flex
    flex-wrap
    justify-center
    text-center
  `}
`
export const CardListWrapper = styled.div`
  ${tw`
    bg-white
    rounded-b-lg
    p-4
  `}
`

export const CardList = styled.div`
  ${tw`
    flex
    flex-col
    justify-between
    space-y-8
    border-t-2
    border-purple-100
    rounded-lg
    pt-10
    pb-8
    px-6
    bg-purple-100
    sm:px-4
    sm:py-4
    lg:h-96
  `}
`

export const CardListItem = styled.li`
  :first-of-type {
    ${tw`
      mt-0
    `}
  }
  ${tw`
    mt-3
    md:mt-1
    flex
    items-start
  `}
  div {
    ${tw`
      flex-shrink-0
    `}
  }
  p {
    ${tw`
      ml-3 
      text-base
      md:ml-2
      md:text-xs
      leading-6
      font-medium
      text-purple-300
    `}
  }
`
export const CardListIcon = styled.div`
  ${tw`
    md:mt-1
    rounded-full
    h-6 
    w-6
    md:h-4
    md:w-4
    flex
    items-center
    justify-center
    bg-purple-900
  `}
  &.cryptr-passwordless {
    ${tw`
      bg-purple-900
    `}
  }
  &.enterprise-connections {
    ${tw`
      bg-teal-500
    `}
  }
  svg {
    ${tw`
      h-4
      w-5
      md:h-3
      md:w-4
      text-white
      font-bold
    `}
  }
`

export const CardTitle = styled.h2`
  ${tw`
    text-center
    text-2xl
    capitalize
    md:text-xl
    leading-9
    font-bold
    text-gray-900
    sm:-mx-6
  `}
  &.cryptr-passwordless {
    ${tw`
      text-purple-900
    `}
  }
  &.enterprise-connections {
    ${tw`
      text-teal-500
    `}
  }
`

export const CardDescription = styled.div`
  ${tw`
    text-center
    text-base
    md:text-base
    lg:text-sm
    font-normal
    text-purple-300
  `}
`

export const CardPriceNumber = styled.span`
  ${tw`
    text-5xl
    md:text-3xl
    font-normal
    w-full
    text-purple-900
  `}
  &.cryptr-passwordless {
    ${tw`
      font-normal
    `}
    strong {
      ${tw`
        text-purple-900
      `}
    }
  }
  &.enterprise-connections {
    ${tw`
      font-normal
    `}
    strong {
      ${tw`
      text-teal-500
      `}
    }
  }
`

export const CardPricePerMonth = styled.span`
  ${tw`
    w-full
    text-xl
    md:text-base
    text-purple-300
    font-normal
  `}
`
export const CardPriceDescription = styled.span`
  ${tw`
    w-full
    pt-4
    text-xl
    md:text-base
    text-purple-300
    font-medium
  `}
`

export const CardCallToAction = styled.div`
  div {
    ${tw`
      rounded-full
      shadow-md
    `}
  }
  a {
    ${tw`
      block
      w-full
      text-center
      rounded-full
      border
      cursor-pointer
      border-transparent
      px-6
      py-4
      md:px-3
      md:py-1
      text-xs
      leading-6
      font-medium
      text-white
      transition
      ease-in-out
      duration-150
    `}
    &.cryptr-passwordless {
      ${tw`
        bg-purple-900
        hover:bg-purple-800
        focus:outline-none
        focus:border-purple-800
        focus:shadow-outline-purple
      `}
    }
    &.enterprise-connections {
      ${tw`
        bg-teal-500
        hover:bg-teal-400
        focus:outline-none
        focus:border-teal-600
        focus:shadow-outline-teal
      `}
    }
  }
`

export const CardFreeTrialText = styled.div`
  ${tw`
    my-6
    text-center
  `}
  p {
    ${tw`
      text-purple-300
      text-base
      md:text-sm
    `}
  }
`

export const Asterisks = styled.div`
  ${tw`
    mt-6
    text-center
  `}
  div {
    ${tw`
      text-purple-300
      text-sm
      md:text-xs
    `}
    a {
      ${tw`
        underline
        text-purple-700
        hover:text-purple-600
      `}
    }
  }
`
