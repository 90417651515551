import { css } from '@emotion/core'
import styled from '@emotion/styled'
import tw, { theme } from 'twin.macro'

export const Grid = styled.div`
  ${tw`
    mt-12
    
    space-y-4
    sm:space-y-0
    sm:grid
    sm:grid-cols-2
    sm:col-gap-6
    sm:row-gap-12
    lg:grid-cols-2
    lg:col-gap-16
    lg:mx-64
  `}
`
