import { RichText } from 'prismic-reactjs'
import React from 'react'
import {
  Asterisks,
  CardBadge,
  CardCallToAction,
  CardDescription,
  CardList,
  CardListIcon,
  CardListItem,
  CardListWrapper,
  CardPrice,
  CardPriceDescription,
  CardPriceNumber,
  CardPricePerMonth,
  CardPriceWrapper,
  CardTitle,
  CardWrapper,
} from './style'

const Card = ({
  category,
  badge,
  category_title,
  category_description,
  title,
  subtitle,
  description,
  call_to_action,
  call_to_action_path,
  badge_text,
  items,
  asterisks,
}: any) => {
  return (
    <div>
      <CardWrapper>
        <div>
          {badge ? (
            <CardBadge>
              <div>
                <span className={category}>{badge_text}</span>
              </div>
            </CardBadge>
          ) : (
            <CardBadge>
              <div>
                <span className="no-category"></span>
              </div>
            </CardBadge>
          )}
          <CardPriceWrapper>
            <div>
              <CardTitle className={category}>{category_title}</CardTitle>
              <CardDescription>{RichText.render(category_description)}</CardDescription>
              <CardPrice>
                <CardPriceNumber className={category}>{RichText.render(title)}</CardPriceNumber>
                {category === 'community' ? (
                  <CardPricePerMonth>{subtitle}</CardPricePerMonth>
                ) : (
                  <CardPricePerMonth>{subtitle}</CardPricePerMonth>
                )}
                <CardPriceDescription>{description}</CardPriceDescription>
              </CardPrice>
            </div>
          </CardPriceWrapper>
          <CardListWrapper>
            <CardList>
              <ul>
                {items.map((item: { text: string }, index: string | number) => {
                  return (
                    <CardListItem key={index}>
                      <div>
                        <CardListIcon className={category}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </CardListIcon>
                      </div>
                      <p>{item.text}</p>
                    </CardListItem>
                  )
                })}
              </ul>
              <CardCallToAction>
                <div>
                  <a className={category} href={call_to_action_path} aria-describedby="tier-growth">
                    {call_to_action}
                  </a>
                </div>
              </CardCallToAction>
            </CardList>
          </CardListWrapper>
        </div>
      </CardWrapper>
      <Asterisks>
        <div>{RichText.render(asterisks[0].raw)}</div>
        <div>{RichText.render(asterisks[1].raw)}</div>
      </Asterisks>
    </div>
  )
}

export default Card
