import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FaqTextWrapper = styled.div`
  ${tw`
    mt-6
    border-t
    border-gray-200
    pt-10
  `}
  dl {
    ${tw`
      space-y-10
      md:space-y-0
      md:grid
      md:grid-cols-2
      md:grid-rows-2
      md:gap-x-8
      md:gap-y-12
    `}
  }
`

export const FaqTextQAndA = styled.div`
  ${tw`
    space-y-2
  `}
`

export const FaqTextQuestion = styled.dt`
  ${tw`
    text-lg
    leading-6
    font-medium
    text-purple-900
  `}
`

export const FaqTextAnswer = styled.dd`
  ${tw`
    text-base
    leading-6
    text-gray-500
  `}
`
