import React from 'react'
import { Section } from '../../content_sections/style'
import Container from '../../elements/container'
import GridPricingCards from '../../grid_pricing_cards'

const Plan = ({ slug, cardItems }: any) => {
  return (
    <Section className="pricing">
      <Container>
        <GridPricingCards slug={slug} cardItems={cardItems} />
      </Container>
    </Section>
  )
}

export default Plan
