import styled from '@emotion/styled'
import tw from 'twin.macro'

export const GridStyle = styled.div`
  ${tw`
    mt-10
    sm:grid
    sm:grid-cols-2
    sm:gap-x-8
    sm:gap-y-6
    lg:col-span-2
    lg:mt-0
  `}
`

export const GridItem = styled.div`
  :not:first-of-type {
    ${tw`
      mt-10
      sm:mt-0
    `}
  }
  div {
    ${tw`
      mt-5
    `}
  }
  h3 {
    ${tw`
      pl-3
      text-base
      leading-6
      font-medium
      border-l-4 
    `}
  }
  h3.pricing {
    ${tw`
      text-pink-500
      border-pink-500
    `}
  }
  p {
    ${tw`
      mt-2
      text-base
      leading-6
      text-gray-500
    `}
  }
`
