import React from 'react'
import ContentSection from '../../content_sections'
import TwoCols from '../../content_sections/grids/two_cols'
import ContentTitleSection from '../../content_sections/titles/content_title'
import TwoXTwoGrid from '../../content_sections/two_x_two_grid'

const OemPricing = ({ oemPricingData, pageID }: any) => {
  return (
    <ContentSection className="dark-blue">
      <TwoCols
        leftColumn={
          <ContentTitleSection
            slug={pageID}
            className="on-dark-background"
            title={oemPricingData.primary.title}
            description={oemPricingData.primary.description}
            call_to_action={oemPricingData.primary.call_to_action}
            call_to_action_path={oemPricingData.primary.call_to_action_path}
            isCallToActionBtn={true}
          />
        }
        rightColumn={<TwoXTwoGrid slug={pageID} items={oemPricingData.features} />}
      />
    </ContentSection>
  )
}

export default OemPricing
