import React from 'react'
import { Grid } from './style'
import PricingCard from '../pricing_card'

const GridPricingCards = ({ cardItems }: any) => {
  return (
    <Grid>
      {cardItems.map(
        (
          card_item: {
            primary: {
              category_title: string
              category_description: { raw: any }
              badge: string
              card_title: { raw: any }
              card_subtitle: string
              card_description: string
              call_to_action: string
              card_info: string
              call_to_action_path: string
              annotation_01: string
              annotation_02: string
            }
            items: any
            card_info: string
          },
          index: string | number,
        ) => {
          const asterisks = [card_item.primary.annotation_01, card_item.primary.annotation_02]
          const categoryClass = card_item.primary.category_title.toLowerCase().replace(' ', '-')

          return (
            <div key={index}>
              <PricingCard
                badge={true}
                category={categoryClass}
                category_title={card_item.primary.category_title}
                category_description={card_item.primary.category_description.raw}
                badge_text={card_item.primary.badge}
                title={card_item.primary.card_title.raw}
                subtitle={card_item.primary.card_subtitle}
                description={card_item.primary.card_description}
                call_to_action={card_item.primary.call_to_action}
                call_to_action_path={card_item.primary.call_to_action_path}
                items={card_item.items}
                asterisks={asterisks}
              />
            </div>
          )
        },
      )}
    </Grid>
  )
}

export default GridPricingCards
