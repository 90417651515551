import React from 'react'
import ContentSection from '../../content_sections'
import FaqText from '../../content_sections/faq'
import ContentTitleFull from '../../content_sections/titles/content_title_full'

const Faqs = ({ faqsData, pageID }: any) => {
  return (
    <ContentSection>
      <ContentTitleFull slug={pageID} className="" title={faqsData.primary.title} />
      <FaqText items={faqsData.items} />
    </ContentSection>
  )
}

export default Faqs
